import { gsap } from "gsap";

import SplitText from "./SplitText";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Power0 } from "gsap";

export default class AnimationsHelper {
  constructor() {
    const getInertiaTimeline = (name = undefined) => {
      const generateRandomIntegerInRange = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      };
      if (!name) {
        name = generateRandomIntegerInRange(1, 5000);
      }

      const id = name;
      if (!window._ticker) {
        window._ticker = {};
      }
      if (!window._proxyTimeline) {
        window._proxyTimeline = {};
      }
      if (!window._timeline) {
        window._timeline = {};
      }
      window._proxyTimeline[id] = gsap.to(
        {},
        {
          paused: true,
        }
      );
      window._timeline[id] = gsap.timeline({
        paused: true,
      });
      window._ticker[id] = () => {
        try {
          let progress = window._timeline[id].progress();
          let proxyProgress = window._proxyTimeline[id].progress();

          progress += (proxyProgress - progress) * 0.095; // inertia velocity, from 0.5 to 0.01

          window._timeline[id].progress(progress);
        } catch (e) {}
      };
      gsap.ticker.add(window._ticker[id]);

      return [window._timeline[id], window._proxyTimeline[id]];
    };

    const sectionScrollTimeline = (selector) => {
      const section_one = document.querySelector(selector + " .section_one");
      const section_two = document.querySelector(selector + " .section_two");
      const bigSection_one = document.querySelectorAll(
        selector + " .bigSection_one"
      );
      const bigSection_two = document.querySelectorAll(
        selector + " .bigSection_two"
      );

      const [tl, proxyScrollText] = getInertiaTimeline();
      const [tl2, proxyScrollText2] = getInertiaTimeline();
      const [tl3, proxyScrollText3] = getInertiaTimeline();
      const [tl4, proxyScrollText4] = getInertiaTimeline();

      if (section_one && bigSection_one) {
        tl.fromTo(
          section_one,
          {
            //alpha: .3,
            y: 200,
            ease: "none",
          },
          {
            scrollTrigger: {
              trigger: selector,
              start: "-" + window.innerHeight + "px top",
              end: "center-=" + window.innerHeight / 2 + "px center",
              scrub: 0,
              once: true,
              invalidateOnRefresh: true,
              onUpdate: (self) => {
                try {
                  proxyScrollText.progress(self.progress.toFixed(12));
                } catch (e) {}
              },
              ease: "none",
            },
            ease: "none",
            y: 0,
            duration: 2.5,
            //alpha: 1,
          }
        );

        tl2.fromTo(
            bigSection_one,
          {
            //alpha: .3,
            y: 900,
            ease: "none",
          },
          {
            scrollTrigger: {
              trigger: selector,
              start: "-" + window.innerHeight + "px top",
              end: "center-=" + (window.innerHeight / 2) + "px center",
              scrub: 0,
              once: true,
              invalidateOnRefresh: true,
              onUpdate: (self) => {
                try {
                  proxyScrollText2.progress(self.progress.toFixed(12));
                } catch (e) {}
              },
              ease: "none",
            },
            ease: "none",
            y: 0,
            duration: 3,
            //alpha: 1,
          }
        );

        tl3.fromTo(
            bigSection_two,
          {
            //alpha: .6,
            y: 200,
            ease: "none",
          },
          {
            scrollTrigger: {
              trigger: selector,
              start: "-" + window.innerHeight + "px top",
              end: "center center",
              scrub: 0,
              once: true,
              invalidateOnRefresh: true,
              onUpdate: (self) => {
                try {
                  proxyScrollText3.progress(self.progress.toFixed(12));
                } catch (e) {}
              },
              ease: "none",
            },
            ease: "none",
            y: 0,
            duration: 3,
            //alpha: 1,
          }
        );

        tl4.fromTo(
            section_two,
            {
              //alpha: .5,
              y: 300,
              ease: "none",
            },
            {
              scrollTrigger: {
                trigger: selector,
                start: "top center",
                end: "bottom center",
                scrub: 0,
                once: true,
                invalidateOnRefresh: true,
                onUpdate: (self) => {
                  try {
                    proxyScrollText.progress(self.progress.toFixed(12));
                  } catch (e) {}
                },
                ease: "none",
              },
              ease: "none",
              y: 0,
              duration: 2.5,
              //alpha: 1,
            }
          );
      }
    };

    this.firstLoad = () => {
      gsap.registerPlugin(ScrollTrigger);
      gsap.config({ force3D: true });
      gsap.ticker.fps(120);

      window.scrollTo({ top: 0 });

      setTimeout(() => {
        let tl = getInertiaTimeline();

        sectionScrollTimeline("#restaurants");
      }, 250);
    };
  }
}
// /**
//  * Scrolltrigger animazione sezione
//  * @param {string} selector
//  */
// const sectionScrollTimeline = (selector) => {
//   // const tlScrollText = gsap.timeline();
//   // const titleHtml = document.querySelector(selector + " .titleContent");
//   const split = document.querySelectorAll(selector + " .quote");
//   const block = document.querySelectorAll(selector + " .block");
//   const title = document.querySelectorAll(selector + " .title");

//   if (split && document.body.clientWidth >= 1200) {
//     // const computedLhTitle = window
//     //   .getComputedStyle(titleHtml)
//     //   .getPropertyValue("line-height");
//     // const lHeightTitle =
//     //   computedLhTitle === "normal" ? 2 * 16 + "px" : computedLhTitle;

//     const splitText = new SplitText(split);

//     const mainTimeline = gsap.timeline({
//       scrollTrigger: {
//         trigger: selector,
//         scrub: 0,
//         smooth:2,
//         start: selector=="#mailorder" ? "top-=900px center" : selector=="#about" ? "top-=30% center" : selector=="#reach" ? "top center" : selector=="#restaurantsTop" ? "top +=500px top" : selector=="#restaurantsBottom" ? "top +=1600px center" : null,
//         end: selector=="#mailorder" ? "bottom 50%" : selector=="#about" ? "bottom-=80% center" : selector=="#reach" ? "bottom center" : selector=="#restaurantsTop" ? "top center" : selector=="#restaurantsBottom" ? "bottom +=70% bottom" : null,
//         // onToggle: (self) =>
//         //   gsap.to("#quote", { opacity: self.isActive ? 1 : 0 }),
//         // onUpdate: (self) =>
//         //     gsap.to("#quote", { lineHeight: parseFloat(lHeightTitle) * (1.5 - self.progress) + "px" }),
//         // toggleActions: "restart pause resume none",
//         once: true,
//       },
//     });

//     const textAnimation = gsap.timeline();

//     // const split = new SplitText("#quote");
//     // textAppearTimeline.from(split.lines, {
//     //   duration: 0.8,
//     //   // opacity: 0,
//     //   // scale: 0,
//     //   y: 80,
//     //   overflow: "hidden",
//     //   height: "0px",
//     //   // rotationX: 180,
//     //   // transformOrigin: "0% 50% -50",
//     //   ease: "back",
//     //   stagger: 0.01,
//     // });

//     mainTimeline
//     .add("start")
//     .from(block, {
//       duration: 0.8,
//       y: 200
//     }
//     , "start"
//     )
//     .from(split, {
//       duration: 0.8,
//       y: selector=="#reach" ? -5 : 20,
//       overflow: "hidden",
//       height: "0px",
//       ease: "none",
//       stagger: 0.01,
//       opacity:0,
//     }, selector=="#reach" ? "<0" : "<+1")
//     .fromTo(
//       splitText.lines,
//       {
//         marginBottom: "10px",
//       },
//       {
//         duration: 0.8,
//         marginBottom: "0px",
//         ease: "none",
//         stagger: 0.01,
//       }
//       , "<+0.3"
//     );

//     textAnimation.from(title, {
//       duration: 0.6,
//       opacity:0,
//       y: selector=="#reach" ? -5 : 20,
//       overflow: "hidden",
//       height: "0px",
//       ease: "back",
//       delay: 0
//     }, selector=="#reach" ? "<0" : "<+1")
//   }

//   //   const splitText = new SplitText("#quote");
//   //   textAppearTimeline.fromTo(
//   //     splitText.lines,
//   //     {
//   //       marginBottom: "40px",
//   //     },
//   //     {
//   //       duration: 0.8,
//   //       marginBottom: "0px",
//   //       ease: "none",
//   //       stagger: 0.01,
//   //     }
//   //   );
// };

/**
 * Timeline master
 */
// const startingAnimation = () => {
//   sectionScrollTimeline("#mailorder");
//   sectionScrollTimeline("#about");
//   sectionScrollTimeline("#reach");
//   sectionScrollTimeline("#restaurantsTop");
//   sectionScrollTimeline("#restaurantsBottom");
//   sectionScrollTimeline("#head");
// };

// window.onload = () => {
//   gsap.registerPlugin(ScrollTrigger);
//   gsap.registerPlugin(SplitText);
//   startingAnimation();
// };
